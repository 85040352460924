import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import format from 'date-fns/format';
import fromUnixTime from 'date-fns/fromUnixTime';
import es from 'date-fns/locale/es';
import { useObtenerUsuario } from '../../hooks/useGetUsers';
import TransferenciasUsario from './components/TransferenciasUsuario';
import DestinatariosUsuario from './components/DestinatariosUsuario';
import SuspenderUsuario from './components/SuspenderUsuario';
import UserDocuments from './components/Documentos';
import DocId from '../../../img/icons/documentid.svg';
import { LoadingCard } from '../../../components/loading/Loadings';
import ChangeRol from './components/ChangeRol';
import { useConfig } from '../../../context/ConfigsContext';

const Usuario = () =>{

    const navigate = useNavigate();
    const formatDate = (fecha) => {return format(fromUnixTime(fecha), "dd 'de' MMMM 'de' yyyy", { locale: es });}
    const {Capitalize} = useConfig();

    const {id} = useParams();
    const [cliente, load] = useObtenerUsuario(id);

    const [nombre, setNombre] = useState();
    const [segundoNombre, setSegundoNombre] = useState();
    const [apellido, setApellido] = useState();
    const [segundoApellido, setSegundoApellido] = useState();
    
    const [documento, setDocumento] = useState();
    const [tipoDoc, setTipoDoc] = useState();
    const [numeroDoc, setNumeroDoc] = useState();
    const [fechaNac, setFechaNac] = useState();
    const [inspectionId, setinspectionId] = useState('')
    const [applicantId, setapplicantId] = useState('')
   
    const [email, setEmail] = useState();
    const [celular, setCelular] = useState();
    const [pais, setPais] = useState();
    const [ciudad, setCidudad] = useState();
    const [direccion, setDireccion] = useState();
    const [contactos, setContactos] = useState();
    const [facebook, setFacebook] = useState();
    const [instagram, setInstagram] = useState();
    const [perfil, setPerfil] = useState();

    const [fechaReg, setFechaReg] = useState();
    const [lastSing, setLastSing] = useState();
    const [desactivado, setDesactivado] = useState();
    const [toSelf, setToSelf] = useState();
    // const [urlDocumentoD, setUrlDocumentoD] = useState();
    // const [paisD, setPaisD] = useState();
    const [cuentas, setCuentas] = useState();

  useEffect(() => {
    if(cliente){
      setNombre(cliente.nombre.nombre);
      setSegundoNombre(cliente.nombre.segNombre);
      setApellido(cliente.nombre.apellido);
      setSegundoApellido(cliente.nombre.segApellido);
      setDocumento(cliente.documento.urlDocumento);
      setTipoDoc(cliente.documento.tipoDocumento);
      setNumeroDoc(cliente.documento.numeroDocumento);
      setFechaNac(cliente.fechaNacimiento);
      setinspectionId(cliente.inspectionId);
      setapplicantId(cliente.applicantId);
      setEmail(cliente.email);
      setCelular(cliente.celular);
      setPais(cliente.direccion.pais);
      setCidudad(cliente.direccion.ciudad);
      setDireccion(cliente.direccion.direccion);
      setContactos(cliente.contactos)
      if(cliente.redes){
        setFacebook(cliente.redes.facebook);
        setInstagram(cliente.redes.instagram);
      }
      setPerfil(cliente.rol);
      setFechaReg(formatDate(cliente.fechaRegistro));
      setLastSing(formatDate(cliente.lastSign));
      setToSelf(cliente.toSelf);
      setDesactivado(cliente.suspendido);
      if(cliente.toSelf){
        // setUrlDocumentoD(cliente.data().documento.urlDocumento);
        // setPaisD(cliente.data().direccion.pais);
        setCuentas(cliente.cuentas);
      }
    }  
  }, [cliente, navigate]);

    return(
        <div className='dashPage'>
            <Helmet><title>Usuario</title></Helmet>
            <p className="pageTitle">Usuario</p>

            {load ? <LoadingCard /> : <>

            <div className='cardFlex'>
                <div className='card70'>
                <div className="card">
                    <p className='cardTitle'>Información</p>
                        <div className="cardData">
                            <div><p><label>Nombres</label>{Capitalize(nombre+' '+segundoNombre)}</p></div>
                            <div><p><label>Apellidos</label>{Capitalize(apellido+' '+segundoApellido)}</p></div>
                            <div><p><label>Rol</label>{perfil}</p></div>

                            <div><p><label>Tipo de Documento</label>{tipoDoc}</p></div>
                            <div><p><label>Número de Documento</label>{numeroDoc}</p></div>
                            {/* <div className="col-6 col-sm-4"><p><label>Fecha de Expedición</label>{fechaExp}</p></div> */}
                            <div><p><label>Fecha de Nacimiento</label>{fechaNac}</p></div>

                            <div><p><label>Email</label>{email}</p></div>
                            <div><p><label>Celular</label>
                                <a href={`https://wa.me/${celular}`} target="blank_" rel="noopener noreferrer">{celular}</a></p>
                            </div>
                            <div><p><label>País</label>{pais}</p></div>
                            {ciudad && <div><p><label>Ciudad</label>{ciudad}</p></div>}
                            <div><p><label>Dirección</label>{direccion}</p></div>
                            {facebook && <div><p><label>Facebook</label>{facebook}</p></div>}
                            {instagram && <div><p><label>Instagram</label>{instagram}</p></div>}
                        </div>
                        
                    </div>
                <div className="card">
                    <p className='cardTitle'>Documentos</p>
                        <div className="cardDocuments">
                        {applicantId ? <UserDocuments applicantId={applicantId} inspectionId={inspectionId} tipoDoc={tipoDoc}/>
                        : <a href={documento} target="blank_">{tipoDoc}<br/><img src={DocId} alt="Documento" /></a>
                        }
                        </div>
                    </div></div>

                <div className='card30'>
                <div className="card">
                    <p className='cardTitle'>Registros</p>
                    <div className="cardData">
                        <div className='cardData100'><p><label>Fecha de Registro</label>{fechaReg}</p></div>
                        <div className='cardData100'><p><label>Última Conexión</label>{lastSing}</p></div>
                    </div>
                </div>
                <ChangeRol id={id} />
                <SuspenderUsuario setDesactivado={setDesactivado} desactivado={desactivado} id={id} email={email} nombre={nombre} />

                </div>
           </div>   
                {contactos !== 0 && <>
                    {toSelf===true &&
                    <div className="card">
                        <p className='cardTitle'>Cuentas Autoenvio</p>
                        <div className='tablaGeneralDiv'>
                            <table className='tablaGeneral'>
                                <thead>
                                    <tr>
                                        <th>Banco</th>
                                        <th>Titular</th>
                                        <th>Bsb</th>
                                        <th>Cuenta</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {Object.keys(cuentas).map((cuenta,i) => {
                                    return(
                                        <tr key={i}>
                                            <td>{cuentas[cuenta].banco}</td>
                                            <td>{Capitalize(cuentas[cuenta].titular)}</td>
                                            <td>{cuentas[cuenta].bsb}</td>
                                            <td>{cuentas[cuenta].cuenta}</td>
                                        </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    }
                </>}
                <DestinatariosUsuario id={id} />
                <TransferenciasUsario id={id} />
            </>
            }
        </div>
    );
}

export default Usuario;