import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase/firebaseConfig';

const AddTransfer = async(props) => {
  const { table, data } = props;
  return await addDoc(collection(db, table),{
    ...data
  })
}

const AsignarCodigo = async(props) =>{
  const { usuario, codigo } = props
  return await updateDoc(doc(db, 'promociones', usuario),{
    [codigo]: 'Usado'
  });
}

const AgregarComprobante = async(props) => {
  const { id, table, estado, comprobantePago} = props;
  return await updateDoc(doc(db, table, id),{
    estado: estado,
    comprobantePago: comprobantePago
  });
}

export {
  AddTransfer,
  AsignarCodigo,
  AgregarComprobante
};