import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useConfig } from '../../../context/ConfigsContext';
import { useAuth } from '../../../context/AuthContext';
import { useGetBusinessByCode, useGetCeoCodes } from '../../../hooks/useGetInfo';
import { getUnixTime } from 'date-fns';
import { RegistrarAsesor } from '../../data/ManageAsesores';
import { ModalValidar } from '../../../components/modales/Modales';
import { Input, InputDate, InputDisabled, InputOptional, InputPhone } from '../../../components/inputs/Input';
import { Selector } from '../../../components/inputs/Selector';
import { BtnGeneral } from '../../../components/buttons/Boton';
import Check from '../../../img/icons/tick-square.svg';
import ImgPrev from '../../../img/icons/arrow-left-1.svg';
import imgsearch from '../../../img/icons/search.svg';
import Colcambios from '../../../img/logocolcambiosicon.svg'
import { HuboUnError, PopUpValid } from '../../../components/mensajes/MensajesClient';

 
const FormAsesor = () => {

  const {usuario} = useAuth();
  const {OpenModal, setProcesando, Capitalize} = useConfig();
  
  const [id, setId] = useState('');
  const [search, setSearch] = useState('');
  const [ceoId, setCeoId] = useState('');
  const {business, exito, setExito} = useGetBusinessByCode(id);
  const {ceo} = useGetCeoCodes(ceoId);
  
  const [externalUserId, setExternalUserId] = useState();
  const [email, setEmail] = useState();
  const [inspectionId, setInspectionId] = useState();
  const [applicantId, setApplicantId] = useState();

  const [businessId, setBusinessId] = useState();
  const [businessName, setBusinessName] = useState('');
  const [businessAbn, setBusinessAbn] = useState('');
  const [businessType, setBusinessType] = useState('');
  const [logo, setLogo] = useState('');
  const [direccion, setDireccion] = useState('');
  const [pais, setPais] = useState('');
  const [ciudad, setCiudad] = useState('')

  const phoneRef = useRef();

  const tiposdocs = [
    { id: '1', text: 'Pasaporte' },
    { id: '2', text: 'Cédula de Ciudadanía'}, 
    { id: '3', text: 'ID' }
  ]

  const idTransform = (nombre) =>{const lower = nombre.replace(/\s+/g, '').toLowerCase(); return lower;}
  useEffect(()=>{if(usuario){setExternalUserId(usuario.uid);setEmail(usuario.email)}},[usuario])

  useEffect(()=>{
    if(business){
      setPais(business.address.address1.country)
      setDireccion(business.address.address1.address)
      setCiudad(business.address.address1.city)
      setCeoId(business.ceo)
      setBusinessAbn(business.document.numberID)
      setBusinessType(business.document.typeID)
      setBusinessId(business.id)
      setLogo(business.logoWeb)
      setBusinessName(business.name)
    }else{
      if(!exito){
        setSpnId(true);
        setAltId('No encontrado')
      }
    }
  },[business, exito])

  useEffect(()=>{
    if(ceo){
      setApplicantId(ceo.applicantId)
      setInspectionId(ceo.inspectionId)
    }
  },[ceo])



  const fecha = new Date()
  const [nombre, setNombre] = useState('');
  const [sdoNombre, setSdoNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [sdoApellido, setSdoApellido] = useState('');
  const [fechaNac, setFechaNac] = useState('');
  const [tipoDeDocumento, setTipoDeDocumento] = useState('');
  const [numeroDocumento, setNumeroDocumento] = useState('');
  const [celular, setCelular] = useState('');
  const [phone, setPhone] = useState('');
  const code = '';

  const [spnId, setSpnId] = useState(false);
  const [spnName, setSpnName] = useState(false);
  const [spnApellido, setSpnApellido] = useState(false);
  const [spnFechaNac, setSpnFechaNac] = useState(false);
  const [spnTipoDoc,setSpnTipoDoc] = useState(false);
  const [spnDocumento, setSpnDocumento] = useState(false);
  const [spnCelular,setSpnCelular] = useState(false);

  const [altId, setAltId] = useState('');
  const [altName, setAltName] = useState('');
  const [altApellido, setAltApellido] = useState('');
  const [altFechaNac, setAltFechaNac] = useState('');
  const [altDocumento, setAltDocumento] = useState('');
  const [altTipodoc, setAltTipodoc] = useState('');
  const [altCelular,setAltCelular] = useState();

  const ValidarCodigo = (e) =>{
    e.preventDefault();
    if(!search){setSpnId(true); setAltId('Escribe un Código'); return;}
    if(search.length > 6 || search.length < 6 ){setSpnId(true); setAltId('El código es de 6 dígitos'); return;}
    setExito('inicial')
    setId(search);
  }

  const Validar = (e) =>{
    e.preventDefault();
    if (!nombre) {setSpnName(true); setAltName('Ingresa un nombre'); return;}
    if (!apellido) {setSpnApellido(true); setAltApellido('Ingresa un apellido'); return;}
    if (!fechaNac) {setSpnFechaNac(true); setAltFechaNac('Ingresa la fecha de Nacimiento'); return;}
    if (!celular){setSpnCelular(true); setAltCelular('Ingresa el número'); return;}
    const validar = phoneRef.current.validarPhone();
    if (!validar){return}
    if (validar){setPhone(validar)}
    if (!tipoDeDocumento) {setSpnTipoDoc(true); setAltTipodoc('Selecciona'); return;}
    if (!numeroDocumento) {setSpnDocumento(true); setAltDocumento('Ingresa el número'); return;}
    OpenModal(
      <ModalValidar 
      accion={Agregarme} 
      titulo={'Registro Asesor'}
      mensaje={'¿Todos los datos son Correctos?'}
      botonName={'Sí, Registrarme'}
      botonClass={'btnGeneral primaryC'}
    />)
  }

  const Agregarme = () =>{
    setProcesando(true)
    RegistrarAsesor({
      id: externalUserId,
      datos:{
        empresaID: businessId,
        fechaRegistro: getUnixTime(fecha),
        fechaNacimiento: fechaNac,
        usuario: externalUserId,
        rol: 'asesor',
        celular: idTransform(phone),
        email: email,
        nombre: {
          nombre: idTransform(nombre),
          segNombre: sdoNombre ? idTransform(sdoNombre) : '',
          apellido: idTransform(apellido),
          segApellido: sdoApellido ? idTransform(sdoApellido) : '',
        },
        documento:{
          tipoDocumento: tipoDeDocumento,
          numeroDocumento: numeroDocumento,
        },
        direccion:{
          pais: pais,
          ciudad: ciudad,
          direccion: direccion
        },
        verificado: 'verificada',
        applicantId: applicantId,
        inspectionId: inspectionId,
        actualizado: true,
        pep: 'No',
        redes: {
          facebook: '',
          instagram: '',
        },
        encuestas:{
          knowColcambios: 'Por medio de una agencia de estudio',
        }
      }
    }).then(()=>{
      const datos ={
        nombre: Capitalize(nombre),
        email: email
      }
      setProcesando(false);
      OpenModal(<PopUpValid mensaje={'Se ha completado tu registro correctamente.'}/>);
      axios.post('https://server-colcambios.herokuapp.com/api/welcome', {datos})
    }).catch(()=>{
      setProcesando(false);
      OpenModal(<HuboUnError />);
    })
  }
  return (
    <div className='contInnerSumsub'>
      <div className='formCard'>
        <div className='headerAsesor'>
          {logo && <img src={logo} alt={business.name} className='ImgBusiness' />}
          <img src={Colcambios} alt='Colcambios' />
        </div>
        <div className='headerLogin'>
          <h1>Registro de Asesores</h1>
          <h2>{business ? 'Ingresa los datos del formulario, los campos marcados con * son obligarios.' :
          'Ingresa el código de tu empresa para iniciar el registro.'}</h2>
        </div>
        {business ? <>
          <div className='rowInput2'>
            <Input label={'Primer Nombre*'} value={nombre} setValue={setNombre} spn={spnName}
              setSpn={setSpnName} alt={altName} type={'text'} fill={'on'} name="givenName"/> 
            <InputOptional label={'Segundo Nombre'} value={sdoNombre} setValue={setSdoNombre}
              type={'text'}  fill={'on'} name={'segundoNombre'} />
          </div>
          <div className='rowInput2'>
            <Input label={'Primer Apellido*'} value={apellido} setValue={setApellido} spn={spnApellido}
              setSpn={setSpnApellido} alt={altApellido} type={'text'} fill={'on'} name="familyName"/> 
            <InputOptional label={'Segundo Apellido'} value={sdoApellido} setValue={setSdoApellido}
              type={'text'}  fill={'on'} name={'segundoApellido'} />
          </div>
          <div className='rowInput2'>
            <InputDate label={'Fecha de Nacimiento*'} value={fechaNac} setValue={setFechaNac} alt={altFechaNac}
              spn={spnFechaNac} setSpn={setSpnFechaNac} fill={"off"} />
            <InputPhone ref={phoneRef} label={'Celular*'} value={celular} setValue={setCelular} cod={code}
              spn={spnCelular} setSpn={setSpnCelular} alt={altCelular} setAlt={setAltCelular} />
          </div>
          <div className='rowInput2'>
            <Selector label={'Tipo de Documento*'} value={tipoDeDocumento} setValue={setTipoDeDocumento} options={tiposdocs} 
              alt={altTipodoc} spn={spnTipoDoc} setSpn={setSpnTipoDoc}  />
            <Input label={'Número de Documento*'} value={numeroDocumento} setValue={setNumeroDocumento} spn={spnDocumento}
              setSpn={setSpnDocumento} alt={altDocumento} type={"text"} fill={'on'} name="id"/>
          </div>
          <InputDisabled setValue={setEmail} value={email} label='Correo' />
          <InputDisabled setValue={setBusinessName} value={businessName} label='Empresa' />
          <InputDisabled setValue={setBusinessAbn} value={businessAbn} label={businessType} />
          <InputDisabled setValue={setDireccion} value={direccion} label={'Dirección'} />
          <div className='btnsCase'>
            <BtnGeneral text='Regresar' img={ImgPrev} side={'L'} disabled={false}
              type="button" action={()=>window.location.reload()} clases={'primaryC'} large={false} />
            <BtnGeneral text='Registrarme' img={Check} side={'R'} disabled={false}
              type="submit" action={Validar} clases={'primaryC'} large={true} />
          </div>
          
        </>
        : <>
          <Input label={'Código de Empresa'} value={search} setValue={setSearch} spn={spnId}
            setSpn={setSpnId} alt={altId} type={"text"} fill={'on'} name="id"/>
          <div className='btnsCase'>
            <BtnGeneral text='Regresar' img={ImgPrev} side={'L'} disabled={false}
              type="button" action={()=>window.location.reload()} clases={'primaryC'} large={false} />
            <BtnGeneral text='Buscar Empresa' img={imgsearch} side={'R'} disabled={false}
              type="submit" action={ValidarCodigo} clases={'primaryC'} large={true} />
          </div>
      </>}
      </div>
    </div>
  );
}

export default FormAsesor;