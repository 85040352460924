import React, { useEffect, useState } from 'react';
import { usePendientes, useSetIngresos, useWalletPending } from '../../../hooks/useIngresos';
import { useConfig } from '../../../../context/ConfigsContext';
import './ingresos.css';

const IngresosPendientes = ({transfers, dbTable, Moneda}) => {

  const {dateNumbers} = useConfig();
  const {pendientes} = usePendientes(transfers);
  const {wallets} = useWalletPending();
  const [todos, setTodos] = useState([])

  useEffect(()=>{setTodos([...pendientes, ...wallets])},[pendientes, wallets])

  const {ingresos} = useSetIngresos(dbTable);
  const [arrais, setArrais] = useState([]);

  useEffect(()=>{
    if(ingresos.length > 0){ 
      setArrais(ingresos.map((ingreso) => {return ingreso.valor}))
    }
  },[ingresos])

  return (
    <div className="card">
			<p className="cardTitle">Ingresos Pendientes</p>
      <div className='pendingsCards'>
        {todos && todos.length > 0 ? <>
          {todos.map((transferencia, i)=>{
            let preparado = false
            let total = transferencia.transferencia.from ? transferencia.transferencia.from : transferencia.transferencia.totalPagado
            if(arrais.includes(total)){preparado = true;}
            
            return(
              <div className={preparado ? 'pendingsItem itemPreparado' : 'pendingsItem'} key={i}>
                <p>Fecha: <span>{dateNumbers(transferencia.fecha)}</span></p>
                <p>Banco: <span>{transferencia.colcambios.banco}</span></p>
                <p>Valor: <span>{Moneda(total)}</span></p>
              </div>
            )
          })}
        </>
        : <div className="itemsEmpty"><p>No hay Valores Pendientes</p></div>
        }
      </div>
    </div>
  );
}
 
export default IngresosPendientes;