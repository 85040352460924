import React, {  useState, useEffect } from 'react';
import SVG from 'react-inlinesvg'
import { useSales } from '../../hooks/useReports';
import { getUnixTime } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import Win from '../../../img/icons/dollar-circle.svg';
import Fee from '../../../img/icons/coin-1.svg';
import Tasa from '../../../img/icons/chart-square.svg';
import Users from '../../../img/icons/user-square.svg';
import './Informes.css';
import { useConfig } from '../../../context/ConfigsContext';

const InformesInicio = () => {

  const {FormatAud} = useConfig();

  const [mesUnoInicio, setMesUnoInicio] = useState('');
  const [mesUnoFinal, setMesUnoFinal] = useState('');
  const [mesDosInicio, setMesDosInicio] = useState('');
  const [mesDosFinal, setMesDosFinal] = useState('');
  const [numTrans, setNumTrans] = useState(0);
  const [numPrev, setNumPrev] = useState(0);
  const [valor1, setValor1] = useState(0);
  const [valor2, setValor2] = useState(0);
  const [fee1, setFee1] = useState(0);
  const [fee2, setFee2] = useState(0);
  const [tasa1, setTasa1] = useState(0);
  const [tasa2, setTasa2] = useState(0);

  const {mesUno, mesDos, newUsers, oldUsers} = useSales(mesUnoInicio, mesUnoFinal, mesDosInicio, mesDosFinal);

  useEffect(()=>{
    let fecha;
    fecha = new Date().toLocaleDateString("en-US", {timeZone: 'Australia/Canberra'});
    
    const epa = new Date(fecha)
    const timeZone = 'Australia/Canberra'
    
    const mesUnoF = zonedTimeToUtc(new Date(epa.getFullYear(), epa.getMonth(), 1), timeZone);
    const mesUnoL = zonedTimeToUtc(new Date(epa.getFullYear(), epa.getMonth()+1, 1), timeZone);
    const mesDosF = zonedTimeToUtc(new Date(epa.getFullYear(), epa.getMonth()-1, 1), timeZone);
    const mesDosL = zonedTimeToUtc(new Date(epa.getFullYear(), epa.getMonth(), 1), timeZone);
    
    setMesUnoInicio(getUnixTime(mesUnoF))
    setMesUnoFinal(getUnixTime(mesUnoL))
    setMesDosInicio(getUnixTime(mesDosF))
    setMesDosFinal(getUnixTime(mesDosL))
  },[])

  useEffect(()=>{
    if(mesUno){
      setValor1(mesUno.ventas)
      setTasa1(Math.round(mesUno.tasa))
      setFee1(mesUno.fee)
      setNumTrans(mesUno.cantidad)
    }
    if(mesDos){
      setValor2(mesDos.ventas)
      setTasa2(Math.round(mesDos.tasa))
      setFee2(mesDos.fee)
      setNumPrev(mesDos.cantidad)
    }
  },[mesUno, mesDos])

  const Calculo = (a, b) =>{
    const x100 = Math.round((a / b) * 100)
    const resta = 100 - x100
    return Math.abs(resta)    
  }

  const Color = (a, b) =>{
    const p = Math.round((a / b) * 100)
    const porcentage = 100 - p
    if(porcentage < 0){ return true
    }else{return false}
  }

  return (
    <>
      <div className='Resumenes'>
        <div className='ResumeCard'>
          <div>
            <div><SVG src={Win} /></div>
            <p>Ventas del Mes
              <span>{FormatAud(valor1)}</span>
            </p>
          </div>
          <p><span className={Color(valor1, valor2) ? 'markGreen' : 'markRed'}>{Color(valor1, valor2) ? '+' : '-'}{Calculo(valor1, valor2)}%</span> Mes anterior:
            <b><span> {FormatAud(valor2)}</span></b>
          </p>
        </div>
        <div className='ResumeCard'>
          <div>
            <div><SVG src={Fee} /></div>
            <p>Recaudo Por Fee
              <span>{FormatAud(fee1)}</span>
            </p>
          </div>
          <p><span className={Color(fee1, fee2) ? 'markGreen' : 'markRed'}>{Color(fee1, fee2) ? '+' : '-'}{Calculo(fee1, fee2)}%</span> Mes anterior:
            <b><span> {FormatAud(fee2)}</span></b></p>
        </div>
        <div className='ResumeCard'>
          <div>
            <div className=''><SVG src={Tasa} /></div>
            <p>Tasa Promedio
            <span>{FormatAud(tasa1)}</span>
            </p>
          </div>
          <p><span className={Color(tasa1, tasa2) ? 'markGreen' : 'markRed'}>{Color(tasa1, tasa2) ? '+' : '-'}{Calculo(tasa1, tasa2)}%</span> Mes anterior 
            <b><span> {FormatAud(tasa2)}</span></b></p>
        </div>
        <div className='ResumeCard'>
          <div>
            <div className=''><SVG src={Users} /></div>
            <p>Nuevos usuarios
              <span>{newUsers}</span>
            </p>
          </div>
          <p><span className={Color(newUsers, oldUsers) ? 'markGreen' : 'markRed'}>{Color(newUsers, oldUsers) ? '+' : '-'}{Calculo(newUsers, oldUsers)}%</span> Mes anterior 
            <b><span> {oldUsers}</span></b></p>
        </div>
      </div>
      <div className='LabelResumen'>
       <p>Transferencias Procesadas en el Mes: <span>{numTrans}</span><br/>
        Mes anterior: <span>{numPrev}</span></p>
      </div>
    </> 
  )
}

export default InformesInicio;