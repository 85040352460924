import React, {useState, useEffect} from 'react'
import axios from 'axios';
import { ApproveDeposit, CancelDeposit } from '../../../data/AdminWallets';
import { useConfig } from '../../../../context/ConfigsContext';
import { HuboUnError, PopUpValid } from '../../../../components/mensajes/MensajesClient';
import Ingreso from '../../transferencias/components/Ingreso';
import { RecotizarIngreso } from './RecotizarMovs';
import { LoadingCard } from '../../../../components/loading/Loadings';
import { useGetMov, useGetWallet } from '../../../hooks/useWallets';
import CardDoc from '../../../../components/cards/CardDoc';
import VoucherIngreso from '../../../../views/pages/billetera/components/VoucherIngreso';
import { CheckIncome } from './Revisiones';
import { BtnGeneral } from '../../../../components/buttons/Boton';
import ImgCheck from '../../../../img/icons/tick-square.svg';
import ImgX from '../../../../img/icons/close-square.svg';
import { ModalValidar } from '../../../../components/modales/Modales';
import { Link } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import ImgWallet from '../../../../img/icons/walletget.svg'
import { InputDisabled } from '../../../../components/inputs/Input';

const Recarga = (props) => {

  const {id, type} = props;
  const [mov, loading] = useGetMov(id, type);
  const [uid, setUid] = useState();
  const [walletID, setWalletID] = useState();
  const [rteNombre, setRteNombre] = useState('');
  const [rteCelular, setRteCelular] = useState('');
  const [typeID, setTypeID] = useState('');
  const [idNumber, setIdNumber] = useState('');
  const [wallet] = useGetWallet(walletID)
  const [fondos, setFondos] = useState(0);

  useEffect(()=>{if(wallet){setFondos(wallet.balance)}},[wallet])

  const {OpenModal,setProcesando,FormatCop, FormatAud, horaCO, horaAU, Capitalize, dateEmail} = useConfig();

  const idNuevo = id.substring(0, 6).toUpperCase();
  const [referency, setReferency] = useState('');
  
  const [date, setDate] = useState();
  const [dateExp, setDateExp] = useState();
  const [status, setStatus] = useState('');
  const [empresa, setEmpresa] = useState();

  const [colcambios, setColcambios] = useState();

  const [totalPagado, setTotalPagado] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const [tasa, setTasa] = useState(0);
  const [ammount, setAmmount] = useState(0);
  // const [empresa, setEmpresa] = useState(false);

  const [urlPayment, setUrlPayment] = useState();

  //REVISIONES
  const [nivel1, setNivel1] = useState(false);
  const [nivel2, setNivel2] = useState(false);
  const [nivel3, setNivel3] = useState(false);

  useEffect(()=>{
    if(mov){
      if(mov.empresa){setUid(mov.empresa); setEmpresa(true)}else{setUid(mov.userID); setEmpresa(false)}
      if(mov.cliente){setReferency(mov.cliente.razon)}else{setReferency('Recarga Billetera')}
      setDate(mov.date);
      setDateExp(mov.dateExp);
      setStatus(mov.status);
      setColcambios(mov.colcambios);

      setTotalPagado(mov.values.total);
      setTaxes(mov.values.taxes);
      setTasa(mov.values.tasa);
      setAmmount(mov.values.ammount);

      // setEmpresa(mov.empresa);
      setUrlPayment(mov.urlPayment);

      setUid(mov.userID)
      setWalletID(mov.wallet)
      setRteNombre(mov.nameWallet)

      setRteCelular(mov.userInfo.phone)
      setTypeID(mov.userInfo.document.tipoId)
      setIdNumber(mov.userInfo.document.numberId)

      if(mov.revisiones){
        setNivel1(mov.revisiones.datos)
        setNivel2(mov.revisiones.valores)
        setNivel3(mov.revisiones.ingreso)
      }
    }
  },[mov])

  // const Submit = () =>{
  //   setProcesando(true)
  //   UpdateIncome({
  //     id: id,
  //   }).then(()=>{
  //     setProcesando(false)

  //   }).catch(()=>{
  //     setProcesando(false)
  //     OpenModal(<HuboUnError />)
  //   })
  // }

  const validarProccess = (e) =>{
    e.preventDefault();
    OpenModal(
			<ModalValidar 
				accion={Process} 
				titulo={'Procesar Ingreso'}
				mensaje={'¿Estás seguro de aprobar este ingreso en la billetera?'}
				botonName={'Sí, Procesar'}
				botonClass={'btnGeneral primaryC'}
			/>
		)
  }

  const Process = () =>{
    setProcesando(true)
    ApproveDeposit({
      id: id,
      status: 'Procesada',
      wallet: walletID,
      balance: fondos + ammount
    }).then(async()=>{
      const datos = {
        email: mov.userInfo.email,
        type: 'ingreso',
        estado: 'Procesada',
        id: idNuevo,
        nombre: Capitalize(mov.nameWallet),
        fecha: dateEmail(date).toUpperCase(),
        tasa: FormatCop(tasa),
        aud: FormatAud(ammount),
        cop: FormatCop(totalPagado),
        taxes: FormatCop(taxes),
        msj: 'fue procesada con éxito. A continuación encontrarás la información.'
      }

      await axios.post('https://server-colcambios.herokuapp.com/api/billetera',{datos})
      setProcesando(false)
      OpenModal(<PopUpValid mensaje={'Se ha procesado el ingreso correctamente.'}/>);
    }).catch(()=>{
      setProcesando(false)
      OpenModal(<HuboUnError />)
    })
  }

  const validarCancel = (e) =>{
    e.preventDefault();
    OpenModal(
			<ModalValidar 
				accion={Cancel} 
				titulo={'Cancelar Recarga'}
				mensaje={'¿Estás seguro de cancelar esta Recarga de billetera?'}
				botonName={'Sí, Cancelar'}
				botonClass={'btnGeneral redC'}
			/>
		)
  }

  const Cancel = () =>{
    setProcesando(true)
    CancelDeposit({
      id: id,
      status: 'Cancelada',
      wallet: walletID,
    }).then(async()=>{
      const datos = {
        email: mov.userInfo.email,
        type: 'ingreso',
        estado: 'Cancelada',
        id: idNuevo,
        nombre: Capitalize(mov.nameWallet),
        fecha: dateEmail(date).toUpperCase(),
        tasa: FormatCop(tasa),
        aud: FormatAud(ammount),
        cop: FormatCop(totalPagado),
        taxes: FormatCop(taxes),
        msj: 'fue Cancelada desafortunadamente. A continuación encontrarás su información.'
      }

      await axios.post('https://server-colcambios.herokuapp.com/api/billetera',{datos})
      setProcesando(false)
      OpenModal(<PopUpValid mensaje={'Se ha cancelado la recarga correctamente.'}/>);
    }).catch(()=>{
      setProcesando(false)
      OpenModal(<HuboUnError />)
    })
  }

  return (
    <div className='dashPage'>
      <p className='pageTitle'>Transferencia</p>

      {loading ? <LoadingCard /> :
        <div className="cardFlex">

          <div className="card70">
            <div className="cardTransfer">
              <div className="NTransfer"><p><span>Referencia</span>{idNuevo}</p></div>
              <div className="STransfer">
                <p><span>Estado</span>{status}</p>
              </div>
              <div><p className="FTransfer"><span>Fecha en Colombia</span>{horaCO(date)}</p></div>
              <div><p className="FTransfer"><span>Fecha en Australia</span>{horaAU(date)}</p></div>
            </div>
            
            <div className="card">
              <p className='cardTitle'>Información</p>
              <div className='cardsDataUser'>
                <div className="cardDataUser">
                  <h2>{rteNombre}</h2>
                  <p><span></span></p>
                  <p><span>{typeID}</span>{idNumber}</p>
                  <p><span>Celular</span>{rteCelular}</p>
                  <Link to={`/billetera/${walletID}`} className='btnLink'><SVG src={ImgWallet} /></Link>
                </div>
              </div>
              <div>
                <InputDisabled label={'Razón del Envío'} value={referency} setValue={setReferency} />
              </div>
            </div>
            <div className="card">
              <p className='cardTitle'>Documentos</p>
              {urlPayment ? 
                <CardDoc documento={urlPayment} label={'Documento'} trash={false} action={()=>{}} text={colcambios.banco}/>
                :
                <VoucherIngreso 
                  uid={uid}
                  walletID={walletID}
                  docRef={id}
                  dateExp={dateExp}
                  colcambios={colcambios}
                  empresa={empresa}
                  mov={mov}
                />
              }
            </div>
            {status !== "Cancelada" && status !== "Procesada" &&
              <div className="card">
                <div>
                  { (nivel1 && nivel2 && nivel3) &&
                  <BtnGeneral text='Procesar Recarga' img={ImgCheck} side={'L'} disabled={false} type="button" 
                    action={validarProccess} clases={'primaryC'} large={false} />
                  }
                  <BtnGeneral text='Cancelar Recarga' img={ImgX} side={'L'} disabled={false} type="button" 
                    action={validarCancel} clases={'redC'} large={false} />
                </div>
              </div>
            }
          </div>

          <div className="card30">
            <CheckIncome id={id}
              nivel1={nivel1} setNivel1={setNivel1}
              nivel2={nivel2} setNivel2={setNivel2}
              nivel3={nivel3} setNivel3={setNivel3}
            />

            <div className="card">
              <p className='cardTitle'>Resumen</p>
              <div className="cardResumen">
                <div><label>Valor Pagado</label><span>{FormatCop(totalPagado)}</span></div>
                <div><label>Cargos del Banco</label><p>{taxes !== 0 && "- "}<span>{FormatCop(taxes)}</span></p></div>
                <div><label>Tasa de Cambio</label><span>{FormatCop(tasa)}</span></div>
                <div><label>Valor a Recibir</label><span><b>{FormatAud(ammount)}</b></span></div>
              </div>
            </div>

            <Ingreso id={id} nombre={rteNombre} apellido={''} referency={'billetera'} />

            {status !== "Procesada" &&
              <RecotizarIngreso totalPagado={totalPagado} taxes={taxes} tasa={tasa}
              ammount={ammount} id={id} wallet={walletID} />
            }

            {/* <ChangeStatus status={status} setStatus={setStatus} Submit ={Submit} /> */}
          </div>
        </div>}
    </div>
  )
}

export default Recarga