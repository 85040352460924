import React, { useState} from 'react';
import { BtnActions } from '../../../../components/buttons/Boton';
import { useGetTransfers } from '../../../hooks/useConsultas';
import TableColcambios from '../../../../components/tablas/Table';
import co from '../../../../img/icons/co.svg';
import au from '../../../../img/icons/au.svg';
import nz from '../../../../img/icons/nz.svg';
import '../../../../styles/table.css';

const TransferenciasUsario = ({id}) => {

	//DATOS
	const [table, setTable] = useState('transferencias')
	const [filtros, setFiltros] = useState(['Registrada', 'En Revisión', 'En Proceso', 'Procesada', 'Cancelada'])
	const [busqueda, setBusqueda] = useState({});
	const {transacciones, loading, Next, After, last, first, GetData} = useGetTransfers(table,filtros, busqueda, id);

	return(
		<div className='card'>
			<p className='cardTitle'>Transferencias</p>
			<div className="BtnBox">
				<BtnActions Clases={'GreenColor'} Action={()=>{setTable('transferencias')}} Img={au} Text={'Australia'}  Disbaled={false} />
				<BtnActions Clases={'GreenColor'} Action={()=>{setTable('colombia')}} Img={co} Text={'Colombia'}  Disbaled={false} />
				<BtnActions Clases={'RedColor'} Action={()=>{setTable('newzealand')}} Img={nz} Text={'Nueva Zelanda'} Disbaled={false} />
			</div>
			<TableColcambios
				head={false}
				table={table}
				filtros={filtros}
				setFiltros={setFiltros}
				setBusqueda={setBusqueda}
				loading={loading}
				transacciones={transacciones}
				GetData={GetData}
				Next={Next}
				After={After}
				first={first}
				last={last}
			/>
		</div>
	)
}

export default TransferenciasUsario;
