import React from "react";
import 'react-multi-carousel/lib/styles.css';
import { Link } from "react-router-dom";
import Testimonios from "./components/testimonios/Testimonios";
import Precios from "./components/precios/Precios";
import CalculadoraInicio from "../../components/conversor/CalculadoraInicio";
// import SVG from 'react-inlinesvg'
// import ImgNext from '../../img/icons/arrow-right-1.svg';
// import co from '../../img/icons/co.svg';
// import au from '../../img/icons/au.svg';
// import nz from '../../img/icons/nz.svg';
import '../../styles/Iniciov2.css';

const Inicio = () =>{
  return (
    <div className="contGeneral">
			<div className="newTarget">
				<div className="cardBanner">
					<div className="bannerText">
						<h1><span>Transferimos</span> tu dinero de <span>Colombia</span> a <span>Australia</span> y <span>Nueva Zelanda</span>, y de Australia a Colombia.</h1>
						{/* <div className='cardsCountryIndex'>
							<div className='flagsItem'><SVG src={co} /><SVG src={ImgNext} className='arrowFlag' /><SVG src={au} /><span>De <b>Colombia</b> a <b>Australia</b></span></div>
							<div className='flagsItem'><SVG src={co} /><SVG src={ImgNext} className='arrowFlag' /><SVG src={nz} /><span>De <b>Colombia</b> a <b>Nueva Zelanda</b></span></div>
							<div className='flagsItem'><SVG src={au} /><SVG src={ImgNext} className='arrowFlag' /><SVG src={co} /><span>De <b>Australia</b> a <b>Colombia</b></span></div>
						</div>
						<p>Fácil, Rápido y Seguro.</p> */}
					</div>
					<div className="indexCalc">
						<CalculadoraInicio />
					</div>
				</div>
			</div>
			
			<div className="cardTarget">
				<p>Paga tus <b>estudios, renta, visa, seguro médico</b> o transfiere tus ahorros <b>directamente a tu cuenta en Australia, Nueva Zelanda y Colombia.</b> </p>
				<Link to="/registrate" className='btnGeneral yellowC'>Regístrate ahora</Link>
			</div>
			{/* <div className="calculatorDiv">
				<div>
					<h1 className="h1Banner">Calcula tu<br />Transferencia</h1>
					<p className="pBanner">El costo del servicio puedes incluirlo o adicionarlo en el valor que vas a pagar.<br/><b>No tenemos costos ocultos</b>.</p>
				</div>
			</div> */}
      <Precios />

      <Testimonios />
    </div>
  )
}

export default Inicio;