import React from "react";
import './Boton.css';
import SVG from 'react-inlinesvg';
import { Link } from "react-router-dom";

const BtnGeneral =(props) =>{
	const{
		text,
		img,
		side,
		disabled,
		type,
		action,
		clases,
		large
	}=props

	return(
		<button className={(disabled ? 'btnDisabled ' : 'btnGeneral '+ clases) + (large ? ' btnLarge' : '')} 
		type={type} disabled={disabled} onClick={action}>
			{side==='L' && img && <SVG src={img} />}{text}{side==='R' && img && <SVG src={img} />}
		</button>
	)
}

const BtnLink = (props) =>{
	const{
		link,
		large,
		img,
		side,
		text,
		clases
	} = props;

	return(
		<Link to={link} className={'btnGeneral '+ clases + (large ? ' btnLarge' : '') }>
			{side==='L' && img && <SVG src={img} />}{text}{side==='R' && img && <SVG src={img} />}
		</Link>
	)
}

const BtnLinkAction = (props) =>{
	const{
		link,
		large,
		img,
		side,
		text,
		clases
	} = props;

	return(
		<Link to={link} className={'BtnExports '+ clases + (large ? ' btnLarge' : '') }>
			{side==='L' && img && <SVG src={img} />}{text}{side==='R' && img && <SVG src={img} />}
		</Link>
	)
}

const Button = ({text, disabled, type, action}) =>{
	return (
		<div className="ButtonLoadDiv">
			<button className="buttonLink btnLarge" disabled={disabled} type={type} onClick={action}>{text}</button>
		</div>
	)
}

const ButtonLoad = (props) =>{
  const{ type,disable,text} = props;

  return (
    <div className="ButtonLoadDiv">
      <button type={type} className="buttonLink btnLarge" disabled={disable}>
        {disable ? 'Cargando...' : text}</button>
    </div>
  )
}

const BtnTxt = ({Text, Disabled, Type, Color, Id}) =>{
  return(
    <button className={Color+' '} disabled={Disabled} type={Type}>{Text}</button>
  )
}

const BtnImg = ({Text, Disabled, Type, Color, Img, side, action}) =>{
	return(
		<button className={Color} disabled={Disabled} type={Type}>
			{side==='L' && <SVG src={Img} />}{Text}{side==='R' && <SVG src={Img} />}
		</button>
	)
}

const BtnIcon = ({Action, Id, Type, Img}) =>{
  return <button onClick={Action} data-id={Id} className='BtnIcon' type={Type}><SVG src={Img} /></button>
}

const BtnActions =({Clases, Action, Img, Text, number, Disbaled }) =>{
  return <button className={'BtnExports '+Clases} onClick={Action} type="button" disabled={Disbaled}>
		<SVG src={Img} />{Text}{number > 0 && <span className="bubblePop">{number}</span>}</button>
}

export{
	BtnGeneral,
	BtnLink,
	Button,
	BtnTxt,
	BtnImg,
	ButtonLoad,
	BtnIcon,
	BtnActions,
	BtnLinkAction
}