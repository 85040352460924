import React, {useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useGetTransfers }  from '../../hooks/useGetTransfers';
import { BtnActions, BtnLinkAction } from '../../../components/buttons/Boton';
import TablaTransUser from '../../../components/tablas/TablaTransUser';
import ImgPlus from '../../../img/icons/plus.svg';
import co from '../../../img/icons/co.svg';
import au from '../../../img/icons/au.svg';
import nz from '../../../img/icons/nz.svg';
import '../../../styles/transferencias.css';
import '../../../styles/table.css';

const Transferencias = () => {

  const [table, setTable] = useState('transferencias');
  const [filtros, setFiltros] = useState([]);
  const [busqueda, setBusqueda] = useState({});
	const {transferencias, loading, Next, After, last, first} = useGetTransfers(table, filtros, busqueda);
  
	return (
		<div className='dashPage'>
			<Helmet><title>Transferencias</title></Helmet>
      <p className='pageTitle'>Transferencias</p>
      <div className="card">
        <p className='cardTitle'>Transferencias</p>
        <div className="BtnBox">
          <BtnActions Clases={'GreenColor'} Action={()=>{setTable('transferencias')}} Img={au} Text={'Australia'}  Disbaled={false} />
          <BtnActions Clases={'GreenColor'} Action={()=>{setTable('colombia')}} Img={co} Text={'Colombia'}  Disbaled={false} />
          <BtnActions Clases={'RedColor'} Action={()=>{setTable('newzealand')}} Img={nz} Text={'Nueva Zelanda'} Disbaled={false} />
        </div>
        <div className='TableHead'>
          <BtnLinkAction link={'/nueva-transferencia'} large={false} img={ImgPlus} side={'L'} text={'Nueva Transferencia'} clases={'BlueColor'} />
        </div>
        <TablaTransUser 
        	country={table}
          datos={transferencias}
          load={loading}
          filtros={filtros}
          setFiltros={setFiltros}
          setBusqueda={setBusqueda}
          Next={Next}
          After={After}
          first={first}
          last={last}
        />
      </div>
		</div>
	);
}

export default Transferencias;