import React from "react";
import { useConfig } from "../../context/ConfigsContext";
import { Cargando } from "../loading/Loadings";
import { Modal } from '../modales/Modales';
import Menu from "../menu/Menu";
import SideBar from "../menu/SideBar";
import HeaderDash from "../header/HeaderDash";
import FooterDash from '../footer/FooterDash';
import './dashboard.css'

const Dashboard = ({ Component }) =>{

	const { theme, sideFix, menuOpen } = useConfig();

	return(
		<>
			<div data-theme={theme} className={menuOpen ? 'dashboard blockscroll': 'dashboard'}>
				<Menu />
				<SideBar/>
				<div className={sideFix ? 'dashContent sideFix': 'dashContent' }>
					<HeaderDash />
					<div className="dashInner">
						<Component/> 
						<FooterDash/>
					</div>
				</div>
			</div>
			<Modal/>
			<Cargando />
		</>
	)
}

export default Dashboard;