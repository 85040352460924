import { useState, useEffect } from 'react';
import { db } from '../../firebase/firebaseConfig';
import { query, where, collection, orderBy, getCountFromServer, getAggregateFromServer, count, sum, average } from "firebase/firestore"; 

const useSales = (mesUnoInicio, mesUnoFinal, mesDosInicio, mesDosFinal) => {

	const [mesUno, setMesUno] = useState();
	const [mesDos, setMesDos] = useState();
	const [newUsers, setNewUsers] = useState(0);
	const [oldUsers, setOldUsers] = useState(0);

	useEffect(() =>{
		if(mesUnoInicio){
			const transRef = collection(db, "transferencias");
			const constraints = [where("estado", "==", "Procesada"),where('fecha', '>=', mesUnoInicio), where('fecha', '<=', mesUnoFinal), orderBy('fecha', 'desc')]
			const q = query(transRef, ...constraints, orderBy('transferencia.fee', 'asc'),  orderBy('transferencia.to', 'asc'), orderBy('transferencia.tasa', 'asc'))

			const docsSnap = async() =>{ await getAggregateFromServer(q, {
				fee: sum('transferencia.fee'),
				ventas: sum('transferencia.to'),
				tasa: average('transferencia.tasa'),
				cantidad: count()
			}).then((snapshot) =>{
				setMesUno({
					ventas: snapshot.data().ventas,
					fee: snapshot.data().fee,
					tasa: snapshot.data().tasa,
					cantidad: snapshot.data().cantidad
				})
			})}
			docsSnap()
		}       
	}, [mesUnoInicio, mesUnoFinal]);

	useEffect(() =>{
		if(mesDosInicio){
			const transRef = collection(db, "transferencias");
			const constraints = [where("estado", "==", "Procesada"),where('fecha', '>=', mesDosInicio), where('fecha', '<=', mesDosFinal), orderBy('fecha', 'desc')]
			const q = query(transRef, ...constraints, orderBy('transferencia.fee', 'asc'), orderBy('transferencia.to', 'asc'), orderBy('transferencia.tasa', 'asc'))

			const docsSnap = async() =>{ await getAggregateFromServer(q, {
				fee: sum('transferencia.fee'),
				ventas: sum('transferencia.to'),
				tasa: average('transferencia.tasa'),
				cantidad: count()
			}).then((snapshot) =>{
				setMesDos({
					ventas: snapshot.data().ventas,
					fee: snapshot.data().fee,
					tasa: snapshot.data().tasa,
					cantidad: snapshot.data().cantidad
				})
			})}
			docsSnap()
		}       
	}, [mesDosInicio, mesDosFinal]);

	useEffect(() =>{
		if(mesUnoInicio){
			const userRef = collection(db, "usuarios");
			const q = query(userRef, 
				where('fechaRegistro', '>=', mesUnoInicio), 
				where('fechaRegistro', '<=', mesUnoFinal),
				orderBy('fechaRegistro','desc'));

			const docsSnap = async() =>{await getCountFromServer(q).then((snapshot) =>{setNewUsers(snapshot.data().count)})}
			docsSnap()
		}       
	}, [mesUnoInicio, mesUnoFinal]);

	useEffect(() =>{
		if(mesDosInicio){
			const userRef = collection(db, "usuarios");
			const q = query(userRef, 
				where('fechaRegistro', '>=', mesDosInicio), 
				where('fechaRegistro', '<=', mesDosFinal),
				orderBy('fechaRegistro','desc'));

			const docsSnap = async() =>{await getCountFromServer(q).then((snapshot) =>{setOldUsers(snapshot.data().count)})}
			docsSnap()
		}       
	}, [mesDosInicio, mesDosFinal]);

	return {mesUno, mesDos, newUsers, oldUsers};
}

const useGetInfo = () =>{

	const [totalUsers, setTotalUsers] = useState(0);
	const [totalTransfer, setTotalTransfer] = useState(0);
	const [totalValue, setTotalValue] = useState(0);

	useEffect(() =>{
		const userRef = collection(db, "usuarios");
		const q = query(userRef, orderBy('fechaRegistro','desc'));
		const docsSnap = async() =>{await getCountFromServer(q).then((snapshot) =>{setTotalUsers(snapshot.data().count)})}
		docsSnap()    
	}, []);

	useEffect(() =>{
		const transRef = collection(db, "transferencias");
		const constraints = [where("estado", "==", "Procesada"), orderBy('fecha', 'desc'),  orderBy('transferencia.montoAud', 'asc')]
		const q = query(transRef, ...constraints)

		const docsSnap = async() =>{ await getAggregateFromServer(q, {
			ventas: sum('transferencia.montoAud'),
			cantidad: count()
		}).then((snapshot) =>{
			setTotalTransfer(snapshot.data().cantidad)
			setTotalValue(snapshot.data().ventas)
		})}
		docsSnap()    
	}, []);

	return {totalUsers,  totalTransfer, totalValue}
}

export { 
	useSales,
	useGetInfo
}