import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useGetTransfer } from '../../../client/hooks/useGetTransfers';
import Comprobante from './components/Comprobante';
import CardDoc from '../../../components/cards/CardDoc';
import { LoadingCard } from '../../../components/loading/Loadings';
import ExportInvoice from './functions/ExportInvoice';

const PagarTransfer = () => {

  const { country, id } = useParams();
	const [table, setTable] = useState();
	
  const [transferencia, loading] = useGetTransfer(id, table);
  const [comprobante, setComprobante] = useState('');

	useEffect(()=>{
		if(country){
			if(country === 'australia'){setTable('transferencias')}
			if(country === 'colombia'){setTable('colombia')}
			if(country === 'newzealand'){setTable('newzealand')}
		}
	},[country])

	useEffect(() => {if(transferencia){setComprobante(transferencia.comprobantePago)}}, [transferencia]);

  return loading ? <LoadingCard /> : transferencia &&
		<div className='dashPage'>
			<Helmet><title>Pagar Transferencia</title></Helmet>
			{/* 
			<div className='card'>
				<div>
					<p>Referencia: {id.substring(0, 6).toUpperCase()}</p>
					<p>Estado: {estado}</p>

					<p>Destinatario:{Capitalize(transferencia.destinatario.nombre+' '+transferencia.destinatario.apellido)}</p>
					<p>Razón: {transferencia.razon}</p>

					<p>Banco: {transferencia.cuenta.banco}</p>
					<p>Titular: {transferencia.cuenta.titular}</p>
					<p>BSB: {transferencia.cuenta.bsb}</p>
					<p>Número de Cuenta:{transferencia.cuenta.numeroCuenta}</p>

					<p>Método: {transferencia.transferencia.metodo}</p>

					<p>Total a Pagar:{transferencia.transferencia.totalPagado}</p>
					<p>Tasa: {transferencia.transferencia.tasa}</p>
					<p>Servicio: {transferencia.transferencia.fee}</p>
					<p>Costos Bancarios: {transferencia.transferencia.cargos}</p>
					<p>Total a Recibir: {transferencia.transferencia.montoAud}</p>

					{transferencia.cliente && <p>Cliente: {transferencia.cliente.nombre}</p>}
					{comprobante && <span>{comprobante}</span>}
				</div>
			</div> */}

			<ExportInvoice
				transferencia={transferencia}
				table={table}
				factura={false}
				id={id}
			/>

			{comprobante ? 
				<div className='card'>
					<div className="cardDocuments">
						<CardDoc documento={comprobante} label={'Comprobante'} trash={false} action={()=>{}} />
					</div>
				</div>
			:
				<Comprobante 
					dbTable={table}
					colcambios={transferencia.colcambios}
					fechaMaxPago={transferencia.fechaMaxPago}
					fechaTransfer={transferencia.fecha}
					docRef={id}
					uidUsuario={transferencia.empresa ? transferencia.empresa : transferencia.usuario} 
					rteEmail={transferencia.remitente.email} 
					rteNombre={transferencia.remitente.nombre} 
					banco={transferencia.cuenta.banco}
					bsb={transferencia.cuenta.bsb}
					titular={transferencia.cuenta.titular}
					cuenta={transferencia.cuenta.numeroCuenta}
					total={transferencia.transferencia.from}
					emailAud={transferencia.transferencia.to} 
					emailTasa={transferencia.transferencia.tasa} 
					razon={transferencia.razon} 
					metodo={transferencia.transferencia.metodo}
					empresa={transferencia.empresa ? true : false}
				/>
			}
	</div>
}

export default PagarTransfer;